export const categories = [
  { title: "triggers.accessory", value: "accessory" }, //{title: "Knx Address", value: "knxAddress"},
  { title: "triggers.security", value: "security" },
  { title: "triggers.intercom", value: "intercom" },
  { title: "triggers.scene", value: "scene" },
  { title: "triggers.doorbell", value: "doorbell" },
  { title: "triggers.application", value: "application" },
];
//{title: "Weathe"r", value: "weather"}

export const actionMethods = [
  /*{title: "triggers.set-power-on", value: "setPowerOn"},
  {title: "triggers.set-dimmer-level", value: "setDimmerLevel"},
  {title: "triggers.set-shutter-level", value: "setShutterLevel"},
  {title: "triggers.change-temperature", value: "setTargetPoint"},
  {title: "triggers.set-thermostat-mode", value: "setThermostatMode"},
  {title: "triggers.punch", value: "punch"},
  {title: "triggers.binary-action", value: "binaryAction"},*/
  { title: "triggers.accessory", value: "accessory" },
  { title: "triggers.send-knx-value", value: "sendKnxValue" },
  { title: "triggers.run-scene", value: "runScene" },
  { title: "triggers.change-security-mode", value: "changeSecurityMode" },
  { title: "triggers.call-contact", value: "callContact" },
  { title: "triggers.add-delay", value: "delay" },
  { title: "triggers.send-http-request", value: "sendHttpRequest" },
  { title: "triggers.send-push", value: "sendPushNotification" },
];

export const properties = {
  /*
   * Accessory properties
   * */
  HHAccessorySensor: [
    {
      title: "triggers.trip-state",
      value: "tripped",
      type: "array",
      comparators: [{ title: "triggers.equals-to", value: "=" }],
      values: [
        { title: "triggers.on", value: "true" },
        { title: "triggers.off", value: "false" },
      ],
    },
    {
      title: "triggers.breach-state",
      value: "breachStatus",
      type: "array",
      comparators: [{ title: "triggers.equals-to", value: "=" }],
      values: [
        { title: "triggers.idle", value: "1" },
        { title: "triggers.dismissed", value: "2" },
        { title: "triggers.pending", value: "3" },
        { title: "triggers.breach", value: "4" },
      ],
    },
  ],
  HHAccessorySwitch: [
    {
      title: "triggers.switch",
      value: "powerOn",
      type: "array",
      comparators: [{ title: "triggers.equals-to", value: "=" }],
      values: [
        { title: "triggers.on", value: "true" },
        { title: "triggers.off", value: "false" },
      ],
    },
  ],
  HHAccessoryDimmer: [
    {
      title: "triggers.switch",
      value: "powerOn",
      type: "array",
      comparators: [{ title: "triggers.equals-to", value: "=" }],
      values: [
        { title: "triggers.on", value: "true" },
        { title: "triggers.off", value: "false" },
      ],
    },
    {
      title: "triggers.level",
      value: "level",
      type: "number",
      comparators: [
        { title: "triggers.equals-to", value: "=" },
        { title: "triggers.not-equal", value: "!=" },
        { title: "triggers.greater-than", value: ">" },
        { title: "triggers.greater-or-equal", value: ">=" },
        { title: "triggers.less-than", value: "<" },
        { title: "triggers.less-or-equal", value: "<=" },
      ],
    },
  ],
  HHAccessoryTunableWhite: [
    {
      title: "triggers.switch",
      value: "powerOn",
      type: "array",
      comparators: [{ title: "triggers.equals-to", value: "=" }],
      values: [
        { title: "triggers.on", value: "true" },
        { title: "triggers.off", value: "false" },
      ],
    },
    {
      title: "triggers.brightness-level",
      value: "brightnessValue",
      type: "number",
      comparators: [
        { title: "triggers.equals-to", value: "=" },
        { title: "triggers.not-equal", value: "!=" },
        { title: "triggers.greater-than", value: ">" },
        { title: "triggers.greater-or-equal", value: ">=" },
        { title: "triggers.less-than", value: "<" },
        { title: "triggers.less-or-equal", value: "<=" },
      ],
    },
    {
      title: "triggers.color-absolute-temperature",
      value: "colorAbsoluteTemperature",
      type: "number",
      comparators: [
        { title: "triggers.equals-to", value: "=" },
        { title: "triggers.not-equal", value: "!=" },
        { title: "triggers.greater-than", value: ">" },
        { title: "triggers.greater-or-equal", value: ">=" },
        { title: "triggers.less-than", value: "<" },
        { title: "triggers.less-or-equal", value: "<=" },
      ],
    },
  ],
  HHAccesssoryThermostat: [
    {
      title: "triggers.room-temperature",
      value: "temperature",
      type: "number",
      comparators: [
        { title: "triggers.equals-to", value: "=" },
        { title: "triggers.not-equal", value: "!=" },
        { title: "triggers.greater-than", value: ">" },
        { title: "triggers.greater-or-equal", value: ">=" },
        { title: "triggers.less-than", value: "<" },
        { title: "triggers.less-or-equal", value: "<=" },
      ],
    },
    {
      title: "triggers.heat-set-point",
      value: "heatSetPoint",
      type: "number",
      comparators: [
        { title: "triggers.equals-to", value: "=" },
        { title: "triggers.not-equal", value: "!=" },
        { title: "triggers.greater-than", value: ">" },
        { title: "triggers.greater-or-equal", value: ">=" },
        { title: "triggers.less-than", value: "<" },
        { title: "triggers.less-or-equal", value: "<=" },
      ],
    },
    {
      title: "triggers.cool-set-point",
      value: "coolSetPoint",
      type: "number",
      comparators: [
        { title: "triggers.equals-to", value: "=" },
        { title: "triggers.not-equal", value: "!=" },
        { title: "triggers.greater-than", value: ">" },
        { title: "triggers.greater-or-equal", value: ">=" },
        { title: "triggers.less-than", value: "<" },
        { title: "triggers.less-or-equal", value: "<=" },
      ],
    },
  ],
  HHAccessoryStatusDisplay: [
    {
      title: "triggers.value",
      value: "value",
      type: "string",
      comparators: [
        { title: "triggers.equals-to", value: "=" },
        { title: "triggers.not-equal", value: "!=" },
        { title: "triggers.greater-than", value: ">" },
        { title: "triggers.greater-or-equal", value: ">=" },
        { title: "triggers.less-than", value: "<" },
        { title: "triggers.less-or-equal", value: "<=" },
      ],
    },
  ],
  HHAccessoryAdvThermostat: [
    {
      title: "triggers.room-temperature",
      value: "temperature",
      type: "number",
      comparators: [
        { title: "triggers.equals-to", value: "=" },
        { title: "triggers.not-equal", value: "!=" },
        { title: "triggers.greater-than", value: ">" },
        { title: "triggers.greater-or-equal", value: ">=" },
        { title: "triggers.less-than", value: "<" },
        { title: "triggers.less-or-equal", value: "<=" },
      ],
    },
    {
      title: "triggers.targer-set-point",
      value: "targetSetPoint",
      type: "number",
      comparators: [
        { title: "triggers.equals-to", value: "=" },
        { title: "triggers.not-equal", value: "!=" },
        { title: "triggers.greater-than", value: ">" },
        { title: "triggers.greater-or-equal", value: ">=" },
        { title: "triggers.less-than", value: "<" },
        { title: "triggers.less-or-equal", value: "<=" },
      ],
    },
  ],
  HHAccessoryWindowCovering: [
    {
      title: "triggers.level",
      value: "level",
      type: "number",
      comparators: [
        { title: "triggers.equals-to", value: "=" },
        { title: "triggers.not-equal", value: "!=" },
        { title: "triggers.greater-than", value: ">" },
        { title: "triggers.greater-or-equal", value: ">=" },
        { title: "triggers.less-than", value: "<" },
        { title: "triggers.less-or-equal", value: "<=" },
      ],
    },
  ],
  HHAccessoryDoorLock: [
    {
      title: "triggers.switch",
      value: "powerOn",
      type: "array",
      comparators: [{ title: "triggers.equals-to", value: "=" }],
      values: [
        { title: "triggers.on", value: "true" },
        { title: "triggers.off", value: "false" },
      ],
    },
  ] /*
  * Knx Adress Props
  * */,
  knxAddress: [
    {
      title: "triggers.read-value",
      value: "read",
      type: "string",
      comparators: [{ title: "triggers.equals-to", value: "=" }],
    },
    {
      title: "triggers.write-value",
      value: "write",
      type: "string",
      comparators: [{ title: "triggers.equals-to", value: "=" }],
    },
  ] /*
  * Security Props
  * */,
  security: [
    {
      title: "triggers.mode",
      value: "mode",
      type: "array",
      comparators: [{ title: "triggers.equals-to", value: "=" }],
      values: [
        { title: "triggers.arm-away-begin", value: "4" },
        { title: "triggers.arm-stay-begin", value: "5" },
        { title: "triggers.arm-away", value: "1" },
        { title: "triggers.arm-stay", value: "3" },
        { title: "triggers.disarmed", value: "2" },
      ],
    },
    {
      title: "triggers.state",
      value: "state",
      type: "array",
      comparators: [{ title: "triggers.equals-to", value: "=" }],
      values: [
        { title: "triggers.no-breach", value: "1" },
        { title: "triggers.pending", value: "2" },
        { title: "triggers.burglary-breach", value: "3" },
        { title: "triggers.fire-breach", value: "4" },
        { title: "triggers.smoke-breach", value: "5" },
        { title: "triggers.flood-breach", value: "6" },
      ],
    },
  ] /*
  * Intercom Props
  * */,
  intercom: [
    {
      title: "triggers.incoming-call",
      value: "incomingCall",
      type: "array",
      comparators: [{ title: "triggers.from", value: "=" }],
      values: [
        { title: "Daire 1", value: "1" },
        { title: "Daire 2", value: "2" },
        { title: "Daire 3", value: "3" },
      ],
    },
    {
      title: "triggers.outgoing-call",
      value: "outgoingCall",
      type: "array",
      comparators: [{ title: "triggers.to", value: "=" }],
      values: [
        { title: "Daire 1", value: "1" },
        { title: "Daire 2", value: "2" },
        { title: "Daire 3", value: "3" },
      ],
    },
  ],
  /*
  * Scene Props
  * */
  scene: [
    {
      title: "triggers.scene-run",
      value: "run",
      type: "array",
      comparators: [{ title: "triggers.equals-to", value: "=" }],
      values: [
        { title: "Scene 1", value: "1" },
        { title: "Scene 2", value: "2" },
        { title: "Scene 3", value: "3" },
      ],
    },
  ],
  /*
   *Doorbell Props
   * */

  doorbell: [
    {
      title: "triggers.ringing",
      value: "ringing",
      type: "array",
      comparators: [{ title: "triggers.equals-to", value: "=" }],
      values: [{ title: "triggers.ringing-onstart", value: "onstart" }],
    },
  ] /*
   *Application Props
   * */,
  application: [
    {
      title: "triggers.app-state",
      value: "state",
      type: "array",
      comparators: [{ title: "triggers.equals-to", value: "=" }],
      values: [
        /*{title: "triggers.app-onstart", value: "onappstart"},
        {title: "triggers.timeout-begin", value: "ontimeoutbegin"},*/
        { title: "triggers.timeout-end", value: "ontimeoutend" },
      ],
    },
    {
      title: "triggers.app-update",
      value: "update",
      type: "array",
      comparators: [{ title: "triggers.equals-to", value: "=" }],
      values: [
        {
          title: "triggers.app-onupdate-complete",
          value: "onappupdatecomplete",
        },
      ],
    },
  ] /*
  * Weather Props
  * */,
  weather: [
    {
      title: "Condition",
      value: "condition",
      type: "array",
      comparators: [{ title: "triggers.equals-to", value: "=" }],
      values: [
        { title: "Sunny", value: "sunny" },
        { title: "Rainy", value: "rainy" },
        { title: "Cloudly", value: "cloudly" },
        { title: "Snowy", value: "snowy" },
      ],
    },
  ],
};
